import React, { useContext } from 'react'

import { ButtonLink as AlliumButtonLink } from '@telus-uds/components-web'

import { IButtonLinkFields } from 'contentful-types'
import { SpacerProps } from '../Spacer/Spacer'

import { appendFieldPaths, mapDataAttrsToDataSet } from '../../../utils/dataAttributes'
import { a11yTextRenderer } from '@/siteBuilder/utils/text/TextRenderer'
import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'
import { BlockRenderer } from '../../../renderer/contentful/BlockRenderer'
import BaseModal, { BaseModalProps } from '../BaseModal/BaseModal'
import useModalClick from '../../../../hooks/useModalClick'
import { useIntcmpContent, intcmpProps } from '../../../../hooks/useIntcmpContent'
import alloyEvents from '../../../utils/alloyAnalytics/link'
import useKoodoAnalyticsEvent from '../../../../hooks/useKoodoAnalyticsEvent'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'
import { getIcon } from '../../../utils/getIcon'

export type ButtonLinkProps = {
  text: string
  url?: string
  intcmpRef?: intcmpProps
  analyticsUrlUsage?: IButtonLinkFields['analyticsUrlUsage']
  priority?: 'high' | 'low'
  size?: 'small' | 'medium'
  danger?: boolean
  inverted?: boolean
  fullWidth?: boolean
  width?: 'default' | 'full-width' | 'responsive'
  analyticsEvent?: ContentfulAnalyticsEventType
  target?: '_self' | '_blank' | '_parent' | '_top'
  spacer?: SpacerProps
  baseModal?: BaseModalProps
  icon?: string
  iconPosition?: 'left' | 'right'
  onClick?: (e: MouseEvent) => void
}

const ButtonLink = ({
  text = '',
  url = '/',
  intcmpRef,
  analyticsUrlUsage,
  priority = 'low',
  size = 'medium',
  danger = false,
  inverted = false,
  fullWidth,
  width,
  target = '_self',
  analyticsEvent,
  spacer,
  baseModal,
  icon,
  iconPosition = 'left',
  onClick,
  ...rest
}: ButtonLinkProps) => {
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = React.useState(false)
  const { activeModal, locale, brand } = useContext(SiteBuilderContext)
  const { tryModalClick } = useModalClick(url, baseModal)

  useKoodoAnalyticsEvent(analyticsEvent, isAnalyticsTriggered)

  rest = appendFieldPaths(rest, ['.text#div[1]/div[1]'])

  const handleOnClick = (e: MouseEvent) => {
    tryModalClick(e)
    setIsAnalyticsTriggered(true)
    alloyEvents.linkClick({ url, analyticsUrlUsage })
    if (onClick) onClick(e)
  }

  const getWidth = (): string => {
    if (width !== undefined) {
      if (width === 'full-width') return 'full'

      return width
    }

    if (fullWidth) return 'full'

    return 'default'
  }

  return (
    <div data-testid="button-link-testid">
      {renderSpacer(spacer)}
      {/* // TODO: Extract the base modal logic to removed repeated code in other Links */}
      {baseModal && (
        <BaseModal
          id={baseModal.id}
          entryTitle={baseModal.entryTitle}
          modalMaxWidth={baseModal.modalMaxWidth}
          heading={<BlockRenderer block={baseModal.heading as any} />}
          subHeading={<BlockRenderer block={baseModal.subHeading as any} />}
          components={<BlockRenderer block={baseModal.components as any} />}
          isOpen={activeModal === baseModal.id}
          disableAnalytics={baseModal.disableAnalytics}
        />
      )}
      <AlliumButtonLink
        icon={icon ? getIcon(icon, brand) : undefined}
        iconPosition={icon ? iconPosition.toLowerCase() : undefined}
        href={useIntcmpContent(url, text, intcmpRef)}
        target={target}
        variant={{
          priority: priority.toLowerCase(),
          size: size.toLowerCase(),
          // TODO: danger variant doesn't work if priority is set
          danger,
          inverse: inverted,
          width: getWidth(),
        }}
        onClick={handleOnClick}
        dataSet={mapDataAttrsToDataSet(rest)}
      >
        {a11yTextRenderer(text, locale.language)}
      </AlliumButtonLink>
    </div>
  )
}

export default ButtonLink
